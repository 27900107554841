import React from "react";
import serviceS1 from "@/images/mobile.png";
import serviceS2 from "@/images/planingg.jpg";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <img src={serviceS1} alt="" />
      <h2>Zhvillimi i Aplikacionit</h2>
      <p>
        Në Solxk ne jemi të specializuar në shndërrimin e ideve konceptuale
        të aplikacioneve në transformimi për të sjellë realitete të
        prekshme.Nëse jeni a përfaqësojnë një startup pionier me një koncept
        vizionar ose a kompani e themeluar që kërkon risi, ekipi ynë, ynë
        zhvilluesi dhe projektuesi me përvojë është gati të realizojë tuajin
        Aspiratat. Ne besojmë fort se një aplikacion i suksesshëm -Zhvillimi
        është zhvilluar për të kapërcyer thjesht kodimin që kërkon; një kuptim i
        thellë i qëllimeve tuaja, audiencës së synuar dhe unike Sfidat.Nga
        konceptualizimi deri te vendosja dhe mbështetje të vazhdueshme, ne
        punojmë ngushtë me ju për të Prodhoni aplikacione që jo vetëm plotësojnë
        nevojat tuaja teknike Specifikimet, por edhe i tejkalojnë pritjet tuaja
        për sa i përket Estetika e dizajnit, përdorshmëria dhe performanca.
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={serviceS2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>Planifikimi dhe Strategjia</h3>
          <p>Shërbimet që ne ofrojmë:</p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>Zhvillimi i aplikacionit iOS
              & Zhvillimi i Aplikacionit Android
            </li>
            <li>
              <i className="fa fa-check-square"></i>Sigurimi i cilësisë dhe Duke
              testuar
            </li>
            <li>
              <i className="fa fa-check-square"></i>Kryqi Zhvillim
            </li>
            <li>
              <i className="fa fa-check-square"></i>Dizajni UI/UX: Krijoni
              Intuitiv, miqësor i përdoruesit
            </li>
            <li>
              <i className="fa fa-check-square"></i>Mirëmbajtja e aplikacionit
              dhe -Shkurtër
            </li>
          </ul>
        </div>
      </div>
      <p>
        Pavarësisht nëse jeni një startup, keni një identitet të fortë marke set
        ose një kompani e themeluar që synon tuaj Për të përditësuar praninë
        tuaj vizuale, Solxk është këtu për t'ju ndihmuar për të ndihmuar. Na
        kontaktoni sot për të diskutuar dizajnin tuaj nevojave dhe hidhni hapin
        e parë për të transformuar markën tuaj në një transformuar kryevepër
        vizuale.
      </p>
      <p>
        Në Solxk ne e pranojmë se themeli i një suksesi Projekti i zhvillimit
        të Web-it qëndron në planifikimin e përpiktë.Përpara Shkrimi i një
        rreshti të vetëm kodi ose dizajnimi i një piksel të vetëm ne investojmë
        kohë për të kuptuar qëllimet e biznesit tuaj, Grupet e synuara dhe
        kërkesat tona gjithëpërfshirëse Procesi i planifikimit siguron që
        aspekti i projektit tuaj të trajtohet me kujdes merren parasysh dhe
        ekzekutohen me saktësi.
      </p>
    </div>
  );
};

export default ServiceDetailsContent;
